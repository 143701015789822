import axios from 'axios'

const BASE_URL = document.head.querySelector('base').href + 'api'

axios.create({
	baseURL: BASE_URL
});

axios.interceptors.request.use(config => {		
	let token = document.head.querySelector('meta[name="csrf-token"]')
	let ApiToken = document.head.querySelector('meta[name="api-token"]')
	window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
	
	if (token) {
		config.headers['X-CSRF-TOKEN'] = token.content;
		config.headers['Authorization'] = `Bearer ${ ApiToken.content }`;
	}

	config.url = BASE_URL+'/'+config.url
	return config;
})

axios.interceptors.response.use(
	response => response, 
	error => {
		const response = error.response
		let type = 'error'
		let message = ''

		if(response != undefined){
			if(response.data.message != undefined)
				message = `<strong>${response.data.message}</strong>\n`

			if([405, 500].includes((response.status))){
				console.log(response);
				message = `<strong>Exception: ${response.data.exception}</strong>\n`
				message += `${response.data.message}\n`

			}else if([401, 422].includes((response.status))){
				type = 'warning'
				
				if(response.data.errors != undefined){
					const errors = response.data.errors
					
					if(typeof errors == 'string')
						message += `- ${errors} \n`
					else if(errors instanceof Object)
						Object.keys(errors).forEach(error => {
							if(typeof errors[error] == 'string')
								message += `- ${errors[error]} \n`
							else if(Array.isArray(errors[error]))
								message += `- ${errors[error][0]} \n`
							else 
								message += `- unkown error \n`
						})
					else console.error('errors', response)
				}
				
			}else{
				message = `Terjadi kesalahan`
				console.error('errors', response)
			}
		}else{
			message = 'Network Error'
			console.error('errors', response)
		}

		return Promise.reject({type: type, message: message})
	}
)

window.axios = axios
export default axios